import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";

import { AuthService, UserInterface } from "../core/auth.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { fromPromise } from "rxjs/observable/fromPromise";

interface UserPinCodeVerifyResult {
  verifyResult: Observable<boolean>;
  mesg: string;
}

@Injectable()
export class UserService {
  UserCollection: AngularFirestoreCollection<UserInterface>;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private _http: HttpClient
  ) {}

  userBankUpdate(user: any) {
    return this._http.post(
      `${environment.cloudEndpointGuestTier2}/user/bank/update?key=${environment.cloudEndpointApiKey}`,
      {
        user_bank: user.user_bank,
        user_bank_description: user.user_bank_description,
        invoice_id: user.invoice_id,
      }
    );
  }

  userGet() {
    return this._http.get(
      `${environment.cloudEndpointGuestTier2}/user/user_firebase_uid/get`,
      {
        params: {
          key: environment.cloudEndpointApiKey,
        },
      }
    );
  }

  userPhoneNumberGet(phoneNumber: string) {
    this.UserCollection = this.afs.collection<UserInterface>("users", (ref) =>
      ref.where("phoneNumber", "==", phoneNumber)
    );

    return this.UserCollection.snapshotChanges().pipe(
      switchMap((actions) => {
        return actions.map((action) => {
          const data = action.payload.doc.data();
          const id = action.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  caseUserIdUpdate(caseModel) {
    return this._http.post(
      `${environment.cloudEndpointEgg}/case/case_id/list?key=${environment.cloudEndpointApiKey}`,
      {
        user_id: caseModel.case_id,
        store_name: caseModel.store_name,
      }
      // {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${localStorage.getItem('googleCloudEndpointToken')}`,
      //   }),
      // }
    );
  }

  userLikeUpdate(store_name: string, liked: boolean) {
    let action = "add";
    if (liked) {
      action = "delete";
    }

    return this._http.post(
      `${environment.cloudEndpointGuestTier2}/user/like/${action}?key=${environment.cloudEndpointApiKey}`,
      { store_name: store_name }
    );
  }

  updateFirebaseAccountPhoneNumberAndIcampingCoreNDB(
    firebaseUID: string,
    phoneNumber: string
  ) {
    return this._http.get(
      `${environment.cloudFunctionGuest}/updateFirebaseAccountPhoneNumberAndIcampingCoreNDB`,
      {
        params: {
          uid: firebaseUID,
          phoneNumber: phoneNumber,
        },
      }
    );
  }

  // prettier-ignore
  userPinCodeVerify(pinCode: number, phoneNumber: string): Observable<UserPinCodeVerifyResult> {
    const firebaseUID = localStorage.getItem('firebaseUID');
    const userDoc: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${firebaseUID}`,
    );

    // NOTICE:  由於 userDoc.ref.get().then他會讓我們需 return 的 true/false變成Promise<T>
    // 所以我們必須要使用 Observable.fromPromise 把userDoc.ref.get()給整個包起來，讓他
    // return成為一個 Observable這樣userDoc.set或'此驗證碼非綁定手機使用' 或
    // '找不到該使用者' 他們的return 都可以成為一個Observable<boolean>
    return fromPromise(
      userDoc.ref
        .get()
        .then(doc => {
          if (doc.exists) {
            console.log(`在firestore可以找到該使用者 /user/${firebaseUID}`);
            const user = doc.data();

            if (user.pinCode === pinCode && user.phoneNumber === phoneNumber) {
              console.log('驗證成功，更新 User.verified to true');
              const data: UserInterface = {
                phoneNumber: phoneNumber,
                uid: firebaseUID,
                verified: true
              };

              return userDoc
                .set(data, { merge: true })
                .then((result): any => {
                  // NOTICE: We dont use observableOf(true);
                  return { verifyResult: true, mesg: '驗證成功' };
                });
            } else {
              console.log('核對失敗，此驗證碼非該綁定手機使用');
              return { verifyResult: false, mesg: '核對失敗，此驗證碼非該綁定手機使用' }; // NOTICE: We dont use observableOf(false);
            }
          }
        })
        .catch(error => {
          console.log('找不到該使用者');
          return { verifyResult: false, mesg: '找不到該使用者' }; // NOTICE: We dont use observableOf(false);
        }),
    );

    // prettier-ignore
    // 參考範例 用 take(1)並用map取出資料後即可return我們要的type, 而不會永遠都是Observable.
    // return userDoc.valueChanges().pipe(
    //   take(1),
    //   // NOTICE: Only use map() here and WE CAN NOT USE tap()
    //   // because using tap will only return user entity, rather than boolean.
    //   map(user => {
    //     console.log(user);
    //     if (user.pinCode === pinCode && user.phoneNumber === phoneNumber) {
    //       return true; // NOTICE: We dont use observableOf(true);
    //     } else {
    //       return false; // NOTICE: We dont use observableOf(false);
    //     }
    //   }),
    // );
  }

  userPinCodeCreateByEmail(uid: string, email: string) {
    return this._http.get(
      `${environment.cloudFunctionGuest}/userPinCodeCreateByEmail`,
      //`http://localhost:5000/icamping-tree/us-central1/userPinCodeCreateByEmail`,
      {
        params: {
          uid: uid,
          email: email,
        },
      }
    );
  }

  verifyOldIcampingUser(icampingUserModel) {
    return this._http.get(
      `${environment.cloudFunctionGuest}/verifyOldIcampingUser`,
      {
        params: {
          email: icampingUserModel.email,
          password: icampingUserModel.password,
        },
      }
    );
  }
}
