import { AuthService } from "../core/auth.service";
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../service/home.service';
import { StoreService } from '../service/store.service';
import { UserService } from '../service/user.service';
import { CarouselService } from '../service/carousel.service';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  showAppMenu(): void {
    this._homeService.showMenu$.emit();
  }

  objectKeys = Object.keys;
  facilities: any;
  tags: any;
  areas: any;
  conditions: any;
  storeTop$: any;
  stores$: any;
  experiences$: any;
  carouselsLaptop$: Observable<any[]>;
  carousels$: Observable<any[]>;
  subjects: Array<any> = [];
  x = 0;
  keepRefreshStores: any;


  constructor(
    private auth: AuthService,
    private router: Router,
    private _homeService: HomeService,
    private _userService: UserService,
    private _storeService: StoreService,
    private _imageService: CarouselService,

  ) { }

  ngOnInit() {
    const filter = this._homeService.getStoreFilter();
    const tagFilter = this._homeService.getExperienceFilter().tagFilter;
    this.areas = filter.areaFilter;
    this.conditions = filter.conditionFilter;
    this.facilities = filter.facilityFilter;
    this.tags = tagFilter;
    this.storeTop$ = this._homeService.getStoreTops();
    this.stores$ = this._homeService.getStores();
    this.experiences$ = this._homeService.getExperience();
    this.carousels$ = this._imageService.list();
    this.carouselsLaptop$ = this._imageService.listLaptop();

    this.keepRefreshStores = setInterval(() => {
      this.x++;
      if (this.x > 180) {
        clearInterval(this.keepRefreshStores);
      }
    }, 1000);

    // this._storeService.conditionList().subscribe((c: any) => {
    //   if (c.length > 0) {
    //     const tmp = [];
    //     c[0].name.forEach(n => {
    //       if (n !== '全選') {
    //         tmp.push(n);
    //       }
    //     });
    //     this.conditions = tmp;
    //   }
    // });

    // this._homeService.experienceListName().subscribe((result: any) => {
    //   if (!result.items) {
    //     return;
    //   }
    //   result.items.forEach((experienceObj: any) => {
    //     // console.log(experienceObj);
    //     this._homeService
    //       .experienceImageList(experienceObj.name)
    //       .subscribe((images: any) => {
    //         // console.log(images);

    //         experienceObj['image'] = images[Math.floor(Math.random() * images.length)].thumbnail_600c_url;
    //       });
    //   });

    // });

    // this._homeService.subjectImageList().subscribe((result: any) => {
    //   this.subjects = result;
    // });
  }

  subscribeStore(store) {
    if (!this.auth.userLoggedin) {
      this.router.navigate(['/login']);
      return;
    }

    store.user_like = !store.user_like;
    if (store.user_like) {
      this._storeService.pushLikeStoreName(store.store_name);
    } else {
      this._storeService.removeLikeStoreName(store.store_name);
    }
    this._userService
      .userLikeUpdate(store.store_name, !store.user_like)
      .subscribe((resp: any) => {
        this._storeService.getUserLikedStores();
      });
  }

  onFacilityChange(e) {
    const index = e.element.tabIndex;
    if (index !== 0) {
      this.facilities['全選'] = false;
    } else {
      Object.keys(this.facilities).forEach(k => {
        if (k !== '全選') {
          this.facilities[k] = false;
        }
      });
    }
    this._homeService.setStoreFilter(
      this.areas, this.conditions, this.facilities
    );
    this.x++;
  }

  onAreaChange(e) {
    this._homeService.setStoreFilter(
      this.areas, this.conditions, this.facilities
    );
    this.x++;
  }

  onConditionChange(e) {
    const index = e.element.tabIndex;
    if (index !== 0) {
      this.conditions['全選'] = false;
    } else {
      Object.keys(this.conditions).forEach(k => {
        if (k !== '全選') {
          this.conditions[k] = false;
        }
      });
    }
    this._homeService.setStoreFilter(
      this.areas, this.conditions, this.facilities
    );
    this.x++;
  }

  goStoreSearchResult(resetFilter = false, slogan = '') {
    if (resetFilter) {
      this._homeService.setAllStoreFilter()
    }
    this._homeService.clearStoreCityFilter();
    this._homeService.setDefaultExperienceFilter();
    this._homeService.setStoreSrearchResultSlogan(slogan)
    this.router.navigate(['/store/search-result']);
  }

  goStoreSearchResultForExperience(name: string, tags = [], cities = []) {
    console.log(tags);
    console.log(cities);
    this._homeService.setExperienceFilter(tags, cities);
    this._homeService.setStoreSrearchResultSlogan(name);
    this.router.navigate(['/store/search-result']);
  }

  areaRemove(a: string) {
    return a.replace('營區','');
  }
}
