import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
  FacebookAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "@angular/fire/auth";
import { DeviceDetectorService } from "ngx-device-detector";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { of as observableOf, Observable } from "rxjs";
import { Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { ToastService } from "ng-uikit-pro-standard";
import * as _ from "lodash";
import firebase from "firebase/compat/app";

export interface UserInterface {
  displayName?: any | null;
  device?: string | "";
  email?: any | null;
  fcmTokens?: { [token: string]: true };
  googleAPIAccessToken?: string | null;
  googleCloudEndpointToken?: string | null;
  phoneNumber?: string | null;
  photoURL?: any | null;
  pinCode?: number | "";
  pinRetry?: number | 0;
  providerUID?: string | null;
  providerId?: string | null;
  uid?: any | null;
  verified?: boolean | false;
}

function detectDevice(device: string) {
  switch (device) {
    default:
      return "web";
    case "Unknown":
      return "web";
    case "iPhone":
      return "ios";
    case "iPad":
      return "ios";
    case "Android":
      return "android";
  }
}

@Injectable()
export class AuthService {
  user$: Observable<UserInterface | null>;
  userLoggedin = false;
  deviceInfo = null;
  auth = getAuth();
  recaptchaVerifier!: RecaptchaVerifier;
  verified;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private toastService: ToastService
  ) {
    if (!environment.production) {
      connectAuthEmulator(this.auth, "http://localhost:9099");
      //connectFirestoreEmulator(this.fake_db, "localhost", 8080);
    }

    this.afAuth.onAuthStateChanged((user) => {
      console.log("user:", user);

      if (user && user.uid) {
        //console.log("可以抓到即時的Firebase Auth 使用者資訊");
        console.log(
          "onAuthStateChanged : 可以抓到即時的Firebase Auth 使用者資訊:" +
            JSON.stringify(user)
        );

        // 我們需要用idToken來呼叫Google Cloud Endpoint API
        this.afAuth.currentUser.then((user) => {
          user.getIdToken().then((idToken) => {
            console.log("您好 displayName:", user.displayName);
            localStorage.setItem("googleCloudEndpointToken", idToken);
            console.log("把firebaseUID寫入localStorage");
            console.log("把googleCloudEndpointToken寫入localStorage");
            localStorage.setItem("firebaseUID", user.uid);
            this.updategoogleCloudEndpointToken(user.uid, idToken);

            localStorage.setItem("googleAPIAccessToken", "----");
            localStorage.setItem("oauthType", user.providerData[0].providerId);
            localStorage.setItem("oauthUID", user.providerData[0].uid);
            this.updateUserData(user);
            window.sessionStorage.setItem("keepLoading", "false"); // /store/yssl286
            if (
              window.sessionStorage.getItem("urlBeforeLogin") &&
              window.sessionStorage.getItem("urlBeforeLogin") !== "/"
            ) {
              console.log("urlBeforeLogin ok");
              const url = window.sessionStorage.getItem("urlBeforeLogin");
              //window.sessionStorage.removeItem("urlBeforeLogin");
              this.router.navigate([url]);
            } else {
              console.log("back to root");
              this.router.navigate(["/"]);
            }
          });
        });
        console.log("user is logged in");
        this.userLoggedin = true;
      } else {
        console.log("user not logged in");
        this.userLoggedin = false;
      }
    });

    // fetch userOauth Profile
    this.user$ = this.afAuth.authState.pipe(
      switchMap((_user: any) => {
        console.log("authState.pipe switchMap");
        if (_user) {
          console.log(_user.uid);
          return this.afs
            .doc<UserInterface>(`users/${_user.uid}`)
            .valueChanges();
        } else {
          return observableOf(null);
        }
      })
    );
  }

  ////// OAuth Methods /////
  phoneLogin(
    auth_phone: any,
    phoneNumber: string,
    appVerifier: RecaptchaVerifier
  ): Promise<void> {
    return signInWithPhoneNumber(auth_phone, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        const verificationCode = window.prompt("請輸入手機簡訊驗證碼");
        console.log(confirmationResult);
        if (verificationCode) {
          return confirmationResult.confirm(verificationCode).then((result) => {
            console.log(result);
            window.location.reload();
            setTimeout(() => {
              this.router.navigate(["/"]);
            }, 2000);
          });
        } else {
          return Promise.reject("No verification code entered.");
        }
      })
      .catch((error) => {
        console.error(`Error during phone sign-up:${error}. Redirect to /`);

        let errorMessage = "手機號碼登入失敗";
        if (error.code === "auth/invalid-verification-code") {
          errorMessage = "無效的手機簡訊驗證碼";
        } else if (error === "auth/too-many-requests") {
          errorMessage = "太多嘗試錯誤";
        } else if (error === "No verification code entered.") {
          errorMessage = "未輸入驗證碼";
        }

        this.toastService.error(`手機號碼登入失敗: ${errorMessage}`);
        window.location.reload();
        window.sessionStorage.clear();
        return Promise.reject(error);
      });
  }

  async googleLogin() {
    const provider = new GoogleAuthProvider();
    await this.afAuth.signInWithRedirect(provider);
  }

  async facebookLogin() {
    const provider = new FacebookAuthProvider();
    await this.afAuth.signInWithRedirect(provider);
  }

  signOut() {
    this.afAuth.signOut().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    });
  }

  //// Email/Password Auth ////
  emailSignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        console.log(
          "[ok] auth.service.emailSignUp createUserWithEmailAndPassword"
        );
        return user;
      })
      .catch((error) => {
        console.log(error.code);
        let errMesg = error.code;
        if (error.code === "auth/email-already-in-use") {
          errMesg = "帳號已註冊過愛露營";
        }
        if (error.code === "auth/weak-password") {
          errMesg = "請加強密碼強度";
        }
        this.toastService.error(`新增愛露營帳號錯誤: ${errMesg}`);
        return error.code;
      });
  }

  emailLogin(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        console.log("Icamping user logged ok");
        return user;
      })
      .catch((error) => {
        console.error("Calling signInWithEmailAndPassword but occur error");
        console.log(error);
        return error;
      });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = firebase.auth();
    return fbAuth
      .sendPasswordResetEmail(email)
      .then(() => this.toastService.success(`已送出密碼重設連結到您的信箱`))
      .catch((error) =>
        this.toastService.error(`重新設定密碼發生錯誤 ${error}`)
      );
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.toastService.error(`登入發生錯誤: ${error.message}`);
  }

  // Sets user data to firebase after succesful login
  updateUserData(user: any) {
    let displayName = user.displayName;
    if (user.providerData[0].providerId === "phone") {
      this.verified = true;
      displayName = user.displayName || user.providerData[0].uid;
    }

    //localStorage.setItem('firebaseUID', user.uid);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const device = detectDevice(this.deviceInfo.device);
    const userOauthProfile: UserInterface = {
      uid: user.uid,
      device: device,
      email: user.email,
      providerUID: user.providerData[0].uid,
      providerId: user.providerData[0].providerId,
      //displayName: user.displayName || user.email.split("@")[0],
      displayName: displayName,
      photoURL: user.photoURL || "https://goo.gl/v4F2Lr",
      googleAPIAccessToken: localStorage.getItem("googleAPIAccessToken"),
      //verified: this.verified, // Starting from 20191017 we disable all the phone verification!
    };
    console.log(userOauthProfile);

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );

    return userRef
      .set(userOauthProfile, { merge: true })
      .catch((error) => console.log(error));
  }

  private updategoogleCloudEndpointToken(
    _firebaseUID: string,
    _googleCloudEndpointToken: string
  ) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${_firebaseUID}`
    );
    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log("使用者目前裝置:" + this.deviceInfo.device);

    const device = detectDevice(this.deviceInfo.device);
    const userOauthProfile: UserInterface = {
      device: device,
      uid: _firebaseUID,
      googleCloudEndpointToken: _googleCloudEndpointToken,
    };
    if (userRef.ref.id) {
      console.log(`現在這個使用者的FirebaseUID: ${userRef.ref.id}`);
      userRef.update(userOauthProfile).catch((error) => console.log(error));
    } else {
      console.error("在Firestore抓不到使用者的資訊，所以不更新");
    }
  }

  icampingLocalStorageSet(email: string, uid: string) {
    localStorage.setItem("oauthType", "password");
    localStorage.setItem("oauthUID", email);
    localStorage.setItem("firebaseUID", uid);
  }
}
