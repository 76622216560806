<div class="section magazine-section mt-10">
  <div class="row pt-2 bighero">
    <mdb-carousel [animation]="'slide'" class="carousel-img">
      <mdb-carousel-item *ngFor="let image of carouselsLaptop$ | async">
        <a
          class="carousel-img"
          href="{{ image.routeTo }}"
          target="{{ image.openNewPage ? '_blank' : '_self' }}"
        >
          <img
            class="d-block w-100 carousel-img"
            src="{{ image.thumbnail_1200c_url }}"
            alt="image.fileName"
          />
        </a>
      </mdb-carousel-item>
      <!-- <mdb-carousel-item>
        <img
          class="d-block w-100"
          src="https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg"
          alt="First slide"
        />
      </mdb-carousel-item>
      <mdb-carousel-item>
        <img
          class="d-block w-100"
          src="https://mdbootstrap.com/img/Photos/Slides/img%20(129).jpg"
          alt="Second slide"
        />
      </mdb-carousel-item>
      <mdb-carousel-item>
        <img
          class="d-block w-100"
          src="https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg"
          alt="Third slide"
        />
      </mdb-carousel-item> -->
    </mdb-carousel>
  </div>
  <div class="row pt-2 smallhero">
    <mdb-carousel [animation]="'slide'">
      <mdb-carousel-item *ngFor="let image of carousels$ | async">
        <a
          href="{{ image.routeTo }}"
          target="{{ image.openNewPage ? '_blank' : '_self' }}"
        >
          <img
            class="d-block w-100"
            src="{{ image.thumbnail_600c_url }}"
            alt="image.fileName"
          />
        </a>
      </mdb-carousel-item>
      <!-- <mdb-carousel-item>
              <img
                class="d-block w-100"
                src="https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg"
                alt="First slide"
              />
            </mdb-carousel-item>
            <mdb-carousel-item>
              <img
                class="d-block w-100"
                src="https://mdbootstrap.com/img/Photos/Slides/img%20(129).jpg"
                alt="Second slide"
              />
            </mdb-carousel-item>
            <mdb-carousel-item>
              <img
                class="d-block w-100"
                src="https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg"
                alt="Third slide"
              />
            </mdb-carousel-item> -->
    </mdb-carousel>
  </div>
  <div class="row">
    <a
      class="col bg-grey nb center px-0"
      routerLink="/camp/search"
      mdbBtn
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
    >
      <i class="fa-solid fa-bolt"></i><br />
      快速訂位
    </a>
    <a
      class="col bg-grey center px-0"
      routerLink="/store/search"
      mdbBtn
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
    >
      <i class="fa fa-search"></i><br />
      營地搜尋
    </a>
    <a
      class="col bg-grey center px-0 i-green"
      mdbBtn
      type="button"
      mdbBtn
      color="light"
      (click)="showAppMenu()"
      mdbWavesEffect
    >
      <i class="fa fa-horse"></i><br />
      區域搜尋
    </a>
    <!-- <a
      class="col bg-grey center px-0"
      routerLink=""
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
    >
      <i class="fa fa-caravan-alt"></i><br />
      車宿體驗
    </a> -->
    <a
      class="col bg-grey center px-0"
      href="https://iglamping.tw"
      target="_blank"
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
    >
      <i class="fa fa-campground"></i><br />
      豪華露營
    </a>
    <a
      class="col bg-grey center px-0"
      pageScroll
      [pageScrollOffset]="100"
      [pageScrollDuration]="0"
      href="#experience"
      mdbBtn
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
    >
      <i class="fa fa-kiwi-bird"></i><br />
      熱門搜尋
    </a>
  </div>
  <div class="row pt-4 mb-5">
    <div class="col-12 mr-sm-0 ml-sm-0 px-0 hm">
      <!--營區-->
      <h4 class="h4-responsive pt-2 pb-1">
        <strong>營地搜尋</strong>
      </h4>
      <div class="mt-m2">
        <div class="md-form mt-0 mb-0 dark-grey-text">
          <!-- <h4 class="h4-responsive">
                  <strong>區域搜尋</strong>
                </h4> -->
          <div class="row">
            <div class="d-flex flex-wrap">
              <div
                class="pr-2 mt-0 mb-1"
                *ngFor="let areaKey of objectKeys(areas)"
              >
                <mdb-checkbox
                  [(ngModel)]="areas[areaKey]"
                  [inline]="true"
                  [rounded]="true"
                  (change)="onAreaChange($event)"
                >
                  {{ areaRemove(areaKey) }}
                </mdb-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="md-form">
          <!-- <h4 class="h4-responsive">
                  <strong>熱門搜尋</strong>
                </h4> -->
          <div class="row">
            <div class="d-flex flex-wrap">
              <div
                class="form-group pr-2 mt-0 mb-1"
                *ngFor="let c of objectKeys(conditions); let i = index"
              >
                <mdb-checkbox
                  [(ngModel)]="conditions[c]"
                  [rounded]="true"
                  [tabIndex]="i"
                  (change)="onConditionChange($event)"
                  >{{ c === "全選" ? "不拘" : c }}</mdb-checkbox
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="stores$ | async as stores">
        <div [ngSwitch]="stores">
          <ng-container *ngSwitchCase="!stores">
            <div class="row text-sm-left mt-3">
              <div class="col-lg-3 col-sm-6 col-12 p-sm-2 p-0">
                <app-loading-spinner></app-loading-spinner>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="stores">
            <div class="scrolling-wrapper smallbanner">
              <div
                class="card"
                *ngFor="
                  let store of stores
                    | storeFilter : areas : facilities : conditions : x
                    | storeSlicer : 8 : x
                    | storeLiker : x;
                  let i = index
                "
              >
                <!-- <div class="card row text-sm-left mt-3"> -->
                <!-- <div
                          class="col-lg-3 col-sm-6 col-12 p-sm-2 p-0"
                          *ngFor="
                            let store of stores
                              | storeFilter: areas:facilities:conditions:x;
                            let i = index
                          "
                        >-->
                <div
                  class="view overlay rounded z-depth-1 waves-light"
                  mdbWavesEffect
                  id="{{ store.store_name }}"
                >
                  <img class="img-fluid" src="{{ store.image }}" alt="" />
                  <a [routerLink]="['/store/' + store.store_name]">
                    <div class="mask rgba-white-slight"></div>
                  </a>
                  <div class="ab-action-btn" (click)="subscribeStore(store)">
                    <a
                      mdbBtn
                      floating="true"
                      size="sm"
                      class="waves-light btn-tlight"
                      mdbWavesEffect
                    >
                      <mdb-icon
                        *ngIf="store.user_like"
                        fas
                        icon="heart"
                        class="text-danger"
                      ></mdb-icon>
                      <mdb-icon
                        *ngIf="!store.user_like"
                        fas
                        icon="heart"
                      ></mdb-icon>
                    </a>
                  </div>
                </div>
                <mdb-card-body>
                  <a [routerLink]="['/store/' + store.store_name]">
                    <div class="row mb-0 px-2 text-default tight-txt">
                      <mdb-icon
                        class="mr-1 my-1 pink-text"
                        fas
                        icon="map-pin"
                      ></mdb-icon>
                      <strong> {{ store.city }}・{{ store.district }}</strong>
                      ・
                      <strong *ngIf="store.altitude <= 500"
                        >海拔 {{ store.altitude }}</strong
                      >
                      <strong
                        *ngIf="store.altitude > 500 && store.altitude < 1500"
                        >海拔 {{ store.altitude }}</strong
                      >
                      <strong *ngIf="store.altitude > 1500"
                        >海拔 {{ store.altitude }}</strong
                      >
                    </div>
                  </a>

                  <a
                    class="black-text tight-txt"
                    [routerLink]="['/store/' + store.store_name]"
                  >
                    <h6 class="h6-responsive lh-0 tight-txt mt-4 px-2">
                      <strong>{{ store.store_alias }}</strong>
                    </h6>
                  </a>
                </mdb-card-body>
              </div>
              <!-- <div class="card">
                      <div
                        class="view overlay rounded z-depth-1 waves-light"
                        mdbWavesEffect
                      >
                        <img
                          class="img-fluid"
                          src="https://storage.googleapis.com/icamping-tree.appspot.com/images%2Fstore%2Fsjc395%2Fz8_400_thumb.png?GoogleAccessId=firebase-adminsdk-3xyzq%40icamping-tree.iam.gserviceaccount.com&Expires=7983792000&Signature=zWtIx5ix4OIGIQoCXnHpVEhpeVhbtBbk9j0ysJhAdSrnXjTbl3Dgni49GJbiZwJMfZ1P5ZmQyBYpxuGN7RCjDwWMFKaENvbR0jVtMoS8%2Fyqvf3GNJOQQXeu3lS2oNJtTJa6vltm7D7zuGU6I4IR0m106udlxAwgfac7vgC31XnspMFb%2FhbY7URehXrAtVj1J5DXq8xwMuu507FidQRT%2F0LEEt833VQ731%2F2NgUQI3R6JzlI1XZz6SAt7LsOF0Yf51DZMQvrWOP7grSk%2FUaAMiZPYSB%2FfIwuh8EHHjwq%2F8groj7cMMJy6btKVNNFlNzRh7bMVFlIMpIeG38H6xa%2FKWQ%3D%3D"
                          alt=""
                        />
                        <div (click)="goStoreSearchResult()">
                          <div class="mask rgba-white-slight"></div>
                        </div>
                      </div>
                      <mdb-card-body>
                        <div (click)="goStoreSearchResult()">
                          <div class="row mb-0 text-default tight-txt px-2">
                            <mdb-icon
                              class="mr-1 my-1 pink-text"
                              fas
                              icon="map-pin"
                            ></mdb-icon>
                            <strong>看更多符合搜索的店家</strong>
                          </div>
                        </div>
                      </mdb-card-body>
                    </div> -->
            </div>
            <!-- </div> -->
          </ng-container>
          <div class="row" *ngSwitchCase="stores" class="bigbanner hm">
            <div
              class="card cardfl"
              *ngFor="
                let store of stores
                  | storeFilter : areas : facilities : conditions : x
                  | storeSlicer : 8 : x
                  | storeLiker : x;
                let i = index
              "
            >
              <!-- <div class="card row text-sm-left mt-3"> -->
              <!-- <div
                                      class="col-lg-3 col-sm-6 col-12 p-sm-2 p-0"
                                      *ngFor="
                                        let store of stores
                                          | storeFilter: areas:facilities:conditions:x;
                                        let i = index
                                      "
                                    >-->
              <div
                class="view overlay rounded z-depth-1 waves-light"
                mdbWavesEffect
                id="{{ store.store_name }}"
              >
                <img class="img-fluid" src="{{ store.image }}" alt="" />
                <a [routerLink]="['/store/' + store.store_name]">
                  <div class="mask rgba-white-slight"></div>
                </a>
                <div class="ab-action-btn" (click)="subscribeStore(store)">
                  <a
                    mdbBtn
                    floating="true"
                    size="sm"
                    class="waves-light btn-tlight"
                    mdbWavesEffect
                  >
                    <mdb-icon
                      *ngIf="store.user_like"
                      fas
                      icon="heart"
                      class="text-danger"
                    ></mdb-icon>
                    <mdb-icon
                      *ngIf="!store.user_like"
                      fas
                      icon="heart"
                    ></mdb-icon>
                  </a>
                </div>
              </div>
              <mdb-card-body>
                <a [routerLink]="['/store/' + store.store_name]">
                  <div class="row mb-0 px-2 text-default tight-txt">
                    <mdb-icon
                      class="mr-1 my-1 pink-text"
                      fas
                      icon="map-pin"
                    ></mdb-icon>
                    <strong> {{ store.city }}・{{ store.district }}</strong>
                    ・
                    <strong *ngIf="store.altitude <= 500"
                      >海拔 {{ store.altitude }}</strong
                    >
                    <strong
                      *ngIf="store.altitude > 500 && store.altitude < 1500"
                      >海拔 {{ store.altitude }}</strong
                    >
                    <strong *ngIf="store.altitude > 1500"
                      >海拔 {{ store.altitude }}</strong
                    >
                  </div>
                </a>

                <a
                  class="black-text tight-txt"
                  [routerLink]="['/store/' + store.store_name]"
                >
                  <h6 class="h6-responsive lh-0 tight-txt mt-4 px-2">
                    <strong>{{ store.store_alias }}</strong>
                  </h6>
                </a>
              </mdb-card-body>
            </div>
          </div>
        </div>
      </div>

      <div class="rwdcenter mt-m2">
        <button
          mdbBtn
          type="button"
          class="i-green-bg text-white py-05 rwdbtn"
          rounded="true"
          mdbWavesEffect
          (click)="goStoreSearchResult()"
        >
          <strong>看更多符合搜索的營地</strong>
        </button>
      </div>
    </div>
  </div>
  <div class="row pt-4 mb-5">
    <div class="col-12 mr-sm-0 ml-sm-0 px-0 hm">
      <h4 class="h4-responsive pt-2 pb-1">
        <strong>精選營地</strong>
      </h4>

      <div *ngIf="storeTop$ | async as storeTop">
        <div [ngSwitch]="storeTop">
          <ng-container *ngSwitchCase="!storeTop">
            <div class="row text-sm-left mt-3">
              <div class="col-lg-3 col-sm-6 col-12 p-sm-2 p-0">
                <app-loading-spinner></app-loading-spinner>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="storeTop">
            <div class="scrolling-wrapper smallbanner">
              <div
                class="card"
                *ngFor="
                  let store of storeTop | storeSlicer : 8 : x | storeLiker : x
                "
              >
                <div
                  class="view overlay rounded z-depth-1 waves-light"
                  mdbWavesEffect
                  id="{{ store.store_name }}"
                >
                  <img class="img-fluid" src="{{ store.image }}" alt="" />
                  <a [routerLink]="['/store/' + store.store_name]">
                    <div class="mask rgba-white-slight"></div>
                  </a>
                  <div class="ab-action-btn" (click)="subscribeStore(store)">
                    <a
                      mdbBtn
                      floating="true"
                      size="sm"
                      class="waves-light btn-tlight"
                      mdbWavesEffect
                    >
                      <mdb-icon
                        *ngIf="store.user_like"
                        fas
                        icon="heart"
                        class="text-danger"
                      ></mdb-icon>
                      <mdb-icon
                        *ngIf="!store.user_like"
                        fas
                        icon="heart"
                      ></mdb-icon>
                    </a>
                  </div>
                </div>
                <mdb-card-body>
                  <a [routerLink]="['/store/' + store.store_name]">
                    <div class="row mb-0 text-default tight-txt px-2">
                      <mdb-icon
                        class="mr-1 my-1 pink-text"
                        fas
                        icon="map-pin"
                      ></mdb-icon>
                      <strong> {{ store.city }}・{{ store.district }}</strong>
                      ・
                      <strong *ngIf="store.altitude <= 500"
                        >海拔 {{ store.altitude }}</strong
                      >
                      <strong
                        *ngIf="store.altitude > 500 && store.altitude < 1500"
                        >海拔 {{ store.altitude }}</strong
                      >
                      <strong *ngIf="store.altitude > 1500"
                        >海拔 {{ store.altitude }}</strong
                      >
                    </div>
                  </a>

                  <a
                    class="black-text tight-txt"
                    [routerLink]="['/store/' + store.store_name]"
                  >
                    <h6 class="h6-responsive lh-0 tight-txt mt-4 px-2">
                      <strong>{{ store.store_alias }}</strong>
                    </h6>
                  </a>
                </mdb-card-body>
              </div>

              <!-- <div class="col-lg-3 col-sm-6 col-12 p-sm-2 p-0">
                <div
                  class="view overlay rounded z-depth-1 waves-light"
                  mdbWavesEffect
                >
                  <img
                    class="img-fluid"
                    src="https://storage.googleapis.com/icamping-tree.appspot.com/images%2Fstore%2Fsjc395%2Fz8_400_thumb.png?GoogleAccessId=firebase-adminsdk-3xyzq%40icamping-tree.iam.gserviceaccount.com&Expires=7983792000&Signature=zWtIx5ix4OIGIQoCXnHpVEhpeVhbtBbk9j0ysJhAdSrnXjTbl3Dgni49GJbiZwJMfZ1P5ZmQyBYpxuGN7RCjDwWMFKaENvbR0jVtMoS8%2Fyqvf3GNJOQQXeu3lS2oNJtTJa6vltm7D7zuGU6I4IR0m106udlxAwgfac7vgC31XnspMFb%2FhbY7URehXrAtVj1J5DXq8xwMuu507FidQRT%2F0LEEt833VQ731%2F2NgUQI3R6JzlI1XZz6SAt7LsOF0Yf51DZMQvrWOP7grSk%2FUaAMiZPYSB%2FfIwuh8EHHjwq%2F8groj7cMMJy6btKVNNFlNzRh7bMVFlIMpIeG38H6xa%2FKWQ%3D%3D"
                    alt=""
                  />
                  <div (click)="goStoreSearchResult(true, '愛露營精選店家')">
                    <div class="mask rgba-white-slight"></div>
                  </div>
                </div>
                <mdb-card-body>
                  <div (click)="goStoreSearchResult(true, '愛露營精選店家')">
                    <div class="row mb-0 text-default tight-txt">
                      <mdb-icon
                        class="mr-1 my-1 pink-text"
                        fas
                        icon="map-pin"
                      ></mdb-icon>
                      <strong>看更多精選店家</strong>
                    </div>
                  </div>
                </mdb-card-body>
              </div> -->
            </div>
          </ng-container>
          <div class="row" *ngSwitchCase="storeTop" class="bigbanner hm">
            <div
              class="card cardfl"
              *ngFor="
                let store of storeTop | storeSlicer : 8 : x | storeLiker : x
              "
            >
              <div
                class="view overlay rounded z-depth-1 waves-light"
                mdbWavesEffect
                id="{{ store.store_name }}"
              >
                <img class="img-fluid" src="{{ store.image }}" alt="" />
                <a [routerLink]="['/store/' + store.store_name]">
                  <div class="mask rgba-white-slight"></div>
                </a>
                <div class="ab-action-btn" (click)="subscribeStore(store)">
                  <a
                    mdbBtn
                    floating="true"
                    size="sm"
                    class="waves-light btn-tlight"
                    mdbWavesEffect
                  >
                    <mdb-icon
                      *ngIf="store.user_like"
                      fas
                      icon="heart"
                      class="text-danger"
                    ></mdb-icon>
                    <mdb-icon
                      *ngIf="!store.user_like"
                      fas
                      icon="heart"
                    ></mdb-icon>
                  </a>
                </div>
              </div>
              <mdb-card-body>
                <a [routerLink]="['/store/' + store.store_name]">
                  <div class="row mb-0 text-default tight-txt px-2">
                    <mdb-icon
                      class="mr-1 my-1 pink-text"
                      fas
                      icon="map-pin"
                    ></mdb-icon>
                    <strong> {{ store.city }}・{{ store.district }}</strong>
                    ・
                    <strong *ngIf="store.altitude <= 500"
                      >海拔 {{ store.altitude }}</strong
                    >
                    <strong
                      *ngIf="store.altitude > 500 && store.altitude < 1500"
                      >海拔 {{ store.altitude }}</strong
                    >
                    <strong *ngIf="store.altitude > 1500"
                      >海拔 {{ store.altitude }}</strong
                    >
                  </div>
                </a>

                <a
                  class="black-text tight-txt"
                  [routerLink]="['/store/' + store.store_name]"
                >
                  <h6 class="h6-responsive lh-0 tight-txt mt-4 px-2">
                    <strong>{{ store.store_alias }}</strong>
                  </h6>
                </a>
              </mdb-card-body>
            </div>
          </div>
        </div>
      </div>
      <div class="rwdcenter mt-m2">
        <button
          mdbBtn
          type="button"
          class="i-green-bg text-white py-05 rwdbtn"
          rounded="true"
          mdbWavesEffect
          (click)="goStoreSearchResult(true, '愛露營精選店家')"
        >
          <strong>看更多精選營地</strong>
        </button>
      </div>
    </div>
  </div>
  <div class="row pt-4 mb-5" id="experience">
    <div class="col-12 mr-sm-0 ml-sm-0 px-0 hm">
      <h4 class="h4-responsive pt-2 pb-1">
        <strong>熱門搜尋</strong>
      </h4>
      <div *ngIf="experiences$ | async as experiences">
        <div [ngSwitch]="experiences">
          <ng-container *ngSwitchCase="!experiences">
            <div class="row text-sm-left mt-3">
              <div class="col-lg-3 col-sm-6 col-12 p-sm-2 p-0">
                <app-loading-spinner></app-loading-spinner>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="experiences">
            <div class="scrolling-wrapper smallbanner">
              <div
                class="card"
                *ngFor="let store of experiences | tagFilter : tags : x"
              >
                <div
                  class="view overlay rounded z-depth-1 waves-light"
                  mdbWavesEffect
                  id="{{ store.name }}"
                >
                  <img class="img-fluid" src="{{ store.image }}" alt="" />
                  <a
                    (click)="
                      goStoreSearchResultForExperience(
                        store.name,
                        store.tags,
                        store.cities
                      )
                    "
                  >
                    <div class="mask rgba-white-slight"></div>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row" *ngSwitchCase="experiences" class="bigbanner hm">
            <div
              class="card cardfl"
              *ngFor="let store of experiences | tagFilter : tags : x"
            >
              <div
                class="view overlay rounded z-depth-1 waves-light"
                mdbWavesEffect
                id="{{ store.name }}"
              >
                <img class="img-fluid" src="{{ store.image }}" alt="" />
                <a
                  (click)="
                    goStoreSearchResultForExperience(
                      store.name,
                      store.tags,
                      store.cities
                    )
                  "
                >
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer class="page-footer text-white text-center f-w100">
  <div class="row">
    <div class="col-auto mr-auto"></div>
    <div class="col-auto">
      <a
        class="set-url-target"
        rel="noopener"
        data-mobile-target="_blank"
        data-desktop-target="_blank"
        target="_blank"
        href="https://lin.ee/XzC6RJJ"
        ><span class="sr-only" style="height: 54px; width: 54px">Line</span>
        <div class="svg chaty-animation-jump line-icon-home start-now">
          <svg
            class="ico_d animated pulse infinite"
            width="49"
            height="49"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(0deg)"
          >
            <circle
              class="color-element"
              cx="19.4395"
              cy="19.4395"
              r="19.4395"
              fill="#38B900"
            ></circle>
            <path
              d="M24 9.36561C24 4.19474 18.6178 0 12 0C5.38215 0 0 4.19474 0 9.36561C0 13.9825 4.25629 17.8606 10.0229 18.5993C10.4073 18.6785 10.9565 18.8368 11.0664 19.1797C11.1762 19.4699 11.1487 19.9184 11.0938 20.235C11.0938 20.235 10.9565 21.0528 10.9291 21.2111C10.8741 21.5013 10.6819 22.3456 11.9725 21.8443C13.2632 21.3167 18.8924 17.9398 21.3913 15.1433C23.1487 13.2702 24 11.4234 24 9.36561Z"
              transform="translate(7 10)"
              fill="white"
            ></path>
            <path
              d="M1.0984 0H0.24714C0.10984 0 -2.09503e-07 0.105528 -2.09503e-07 0.211056V5.22364C-2.09503e-07 5.35555 0.10984 5.43469 0.24714 5.43469H1.0984C1.2357 5.43469 1.34554 5.32917 1.34554 5.22364V0.211056C1.34554 0.105528 1.2357 0 1.0984 0Z"
              transform="translate(15.4577 16.8593)"
              fill="#38B900"
              class="color-element"
            ></path>
            <path
              d="M4.66819 0H3.81693C3.67963 0 3.56979 0.105528 3.56979 0.211056V3.19222L1.18078 0.0791458C1.18078 0.0791458 1.18078 0.0527642 1.15332 0.0527642C1.15332 0.0527642 1.15332 0.0527641 1.12586 0.0263821C1.12586 0.0263821 1.12586 0.0263821 1.0984 0.0263821H0.247139C0.10984 0.0263821 4.19006e-07 0.13191 4.19006e-07 0.237438V5.25002C4.19006e-07 5.38193 0.10984 5.46108 0.247139 5.46108H1.0984C1.2357 5.46108 1.34554 5.35555 1.34554 5.25002V2.26885L3.73455 5.38193C3.76201 5.40831 3.76201 5.43469 3.78947 5.43469C3.78947 5.43469 3.78947 5.43469 3.81693 5.43469C3.81693 5.43469 3.81693 5.43469 3.84439 5.43469C3.87185 5.43469 3.87185 5.43469 3.89931 5.43469H4.75057C4.88787 5.43469 4.99771 5.32917 4.99771 5.22364V0.211056C4.91533 0.105528 4.80549 0 4.66819 0Z"
              transform="translate(17.6819 16.8593)"
              fill="#38B900"
              class="color-element"
            ></path>
            <path
              d="M3.62471 4.22112H1.34554V0.237438C1.34554 0.105528 1.2357 0 1.0984 0H0.24714C0.10984 0 -5.23757e-08 0.105528 -5.23757e-08 0.237438V5.25002C-5.23757e-08 5.30278 0.0274599 5.35555 0.0549198 5.40831C0.10984 5.43469 0.16476 5.46108 0.21968 5.46108H3.56979C3.70709 5.46108 3.78947 5.35555 3.78947 5.22364V4.4058C3.87185 4.32665 3.76201 4.22112 3.62471 4.22112Z"
              transform="translate(10.8993 16.8593)"
              fill="#38B900"
              class="color-element"
            ></path>
            <path
              d="M3.56979 1.29272C3.70709 1.29272 3.78947 1.18719 3.78947 1.05528V0.237438C3.78947 0.105528 3.67963 -1.00639e-07 3.56979 -1.00639e-07H0.219679C0.164759 -1.00639e-07 0.10984 0.0263821 0.0549199 0.0527641C0.02746 0.105528 -2.09503e-07 0.158292 -2.09503e-07 0.211056V5.22364C-2.09503e-07 5.2764 0.02746 5.32917 0.0549199 5.38193C0.10984 5.40831 0.164759 5.43469 0.219679 5.43469H3.56979C3.70709 5.43469 3.78947 5.32917 3.78947 5.19726V4.37941C3.78947 4.2475 3.67963 4.14198 3.56979 4.14198H1.29062V3.29775H3.56979C3.70709 3.29775 3.78947 3.19222 3.78947 3.06031V2.24247C3.78947 2.11056 3.67963 2.00503 3.56979 2.00503H1.29062V1.16081H3.56979V1.29272Z"
              transform="translate(23.421 16.8329)"
              fill="#38B900"
              class="color-element"
            ></path>
          </svg></div
      ></a>
    </div>
  </div>

  <div class="row">
    <a
      class="col w-50 i-green-d-bg m-0"
      mdbBtn
      type="button"
      mdbWavesEffect
      routerLink="/camp/search"
    >
      <h5 class="mb-0"><i class="fa fa-rabbit-fast"></i> 快速訂位</h5>
    </a>
    <a
      class="col w-50 i-green-bg m-0"
      mdbBtn
      type="button"
      mdbWavesEffect
      routerLink="/me/order"
    >
      <h5 class="mb-0"><i class="fa fa-campground"></i> 我的行程</h5>
    </a>
  </div>
</footer>

<!-- Copyright -->
