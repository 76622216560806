<div *ngIf="auth.user$ | async">
  <div class="pb-3 mt2r mx-m5" *ngIf="showSpinner">
    <div class="row">
      <app-loading-spinner
        class="col-md-12 mt-4 mb-4 px-0"
      ></app-loading-spinner>
    </div>
  </div>
</div>

<!-- <div class="pb-3 mt2r mx-m5" *ngIf="!(auth.user$ | async)"> -->
<div class="pb-3 mt2r mx-m5">
  <div class="row">
    <app-loading-spinner
      class="col-md-12 mt-4 mb-4 px-0"
      *ngIf="showSpinner"
    ></app-loading-spinner>
    <div class="col-md-12 mt-2 mb-4 px-0">
      <img
        src="../../assets/icons/icon-152x152.png"
        class="icamping-icon mx-auto d-block"
      />
    </div>

    <div class="col-md-12 mt-4 mb-4 px-0">
      <h6>請選擇登入方式</h6>
      <p class="note note-success center">
        <small> 建議使用 愛露營手機帳號登入 </small>
      </p>
    </div>

    <!-- 愛露營手機帳號登入 -->
    <div class="col-md-12 px-0">
      <button
        mdbBtn
        id="signUpPhone-btn"
        class="mx-auto d-block"
        type="button"
        color="success"
        rounded="true"
        mdbWavesEffect
        (click)="phoneLoginDiv.toggle()"
      >
        <i class="fas fa-right-to-bracket pr-1"></i>使用 愛露營手機帳號登入
      </button>
    </div>

    <div class="col-md-12 mt-3 px-0">
      <div mdbCollapse #phoneLoginDiv="bs-collapse">
        <div
          class="d-flex justify-content-center row"
          *ngIf="showPhoneLoginWindow"
        >
          <div class="col"></div>
          <div class="col-md-10 col-12">
            <mdb-card>
              <mdb-card-body class="px-lg-5 pt-0">
                <div class="md-form">
                  <input
                    mdbInput
                    [(ngModel)]="phoneModel"
                    class="form-control w100"
                    type="tel"
                    maxlength="10"
                    [mdbValidation]="false"
                  />
                  <label for="icampingUsername">愛露營手機帳號登入</label>
                </div>
                <button
                  mdbBtn
                  id="signUpPhone-btn"
                  color="success"
                  rounded="true"
                  block="true"
                  class="my-4 waves-effect z-depth-0 w100"
                  (click)="signInWithPhone()"
                >
                  手機號碼登入
                </button>
              </mdb-card-body>
            </mdb-card>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>

    <!-- Facebook登入 -->
    <div class="col-md-12 px-0">
      <button
        mdbBtn
        class="mx-auto d-block"
        type="button"
        color="primary"
        rounded="true"
        mdbWavesEffect
        [disabled]="showSpinner"
        (click)="signInWithFacebook()"
      >
        <i class="fab fa-facebook-f pr-1"></i> 使用 Facebook帳號登入
      </button>
    </div>

    <!-- Google登入 -->
    <div class="col-md-12 mt-3 px-0">
      <div class="d-flex justify-content-center">
        <button
          mdbBtn
          class="mx-auto d-block"
          type="button"
          color="white"
          rounded="true"
          mdbWavesEffect
          [disabled]="showSpinner"
          (click)="signInWithGoogle()"
        >
          <i class="fab fa-google pr-1"></i> 使用 Google 帳號登入
        </button>
      </div>
    </div>

    <!-- 愛露營email登入 -->
    <div class="col-md-12 mt-3 px-0">
      <button
        mdbBtn
        class="mx-auto d-block"
        type="button"
        color="default"
        rounded="true"
        mdbWavesEffect
        (click)="test.toggle()"
      >
        <i class="fas fa-campground"></i> 使用其它帳號登入
      </button>
    </div>

    <!-- 愛露營email登入Form -->
    <div class="col-md-12 mt-3 px-0">
      <div mdbCollapse #test="bs-collapse">
        <app-loading-spinner *ngIf="showSpinner"></app-loading-spinner>
        <div class="d-flex justify-content-center row" *ngIf="showLoginWindow">
          <div class="col"></div>
          <div class="col-md-10 col-12">
            <mdb-card>
              <!-- Card content -->
              <mdb-card-body class="px-lg-5 pt-0">
                <!-- Form -->
                <form class="text-center" style="color: #757575">
                  <!-- Email -->
                  <div class="md-form">
                    <input
                      type="email"
                      id="icampingUsername"
                      name="email"
                      class="form-control w100"
                      [(ngModel)]="icampingUserModel.email"
                      [mdbValidation]="false"
                      mdbInput
                    />
                    <label for="icampingUsername">登入帳號(Mail)</label>
                  </div>

                  <!-- Password -->
                  <div class="md-form">
                    <input
                      type="password"
                      id="icampingPassword"
                      name="password"
                      class="form-control w100"
                      [(ngModel)]="icampingUserModel.password"
                      [mdbValidation]="false"
                      mdbInput
                    />
                    <label for="icampingPassword">密碼</label>
                  </div>

                  <!-- Sign in button -->
                  <button
                    mdbBtn
                    color="success"
                    rounded="true"
                    block="true"
                    class="my-4 waves-effect z-depth-0 w100"
                    id="signInWithIcamping"
                    mdbWavesEffect
                    [disabled]="
                      showSpinner ||
                      !icampingUserModel.email ||
                      !icampingUserModel.password ||
                      !icampingUserModel.email.includes('@')
                    "
                    (click)="signInWithIcamping(icampingUserModel)"
                  >
                    登入
                  </button>

                  <!-- Register -->
                  <div class="row text-center">
                    <a
                      class="waves-light"
                      (click)="resetPasswordToggle.toggle()"
                      >需要重設愛露營帳號密碼?<font color="#e51075">
                        請點我重設</font
                      ></a
                    >
                  </div>

                  <div class="" mdbCollapse #resetPasswordToggle="bs-collapse">
                    <div class="md-form">
                      <input
                        type="text"
                        name="forgotpassword"
                        id="inputSMEx"
                        placeholder="請輸入email帳號"
                        class="form-control w100"
                        [(ngModel)]="icampingUserModelResetPassword.email"
                        [mdbValidation]="false"
                        mdbInput
                      />
                    </div>
                    <div class="md-form">
                      <button
                        class="w100"
                        mdbBtn
                        color="success"
                        rounded="true"
                        block="true"
                        mdbWavesEffect
                        (click)="resetPassword(icampingUserModelResetPassword)"
                      >
                        送出密碼重設申請
                      </button>
                    </div>
                  </div>
                </form>
                <!-- Form -->
              </mdb-card-body>
            </mdb-card>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
</div>
