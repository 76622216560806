import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthService } from "../core/auth.service";
import { Component, OnInit } from "@angular/core";
import { MessagingService } from "../core/messaging.service";
import { PwaService } from "../service/pwa.service";
import { Router } from "@angular/router";
import { take, filter } from "rxjs/operators";
import { ToastService } from "ng-uikit-pro-standard";
import { UserService } from "../service/user.service";
import * as _ from "lodash";
import firebase from "firebase/compat/app";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
  showPushNotificationAction = false;
  showAddToHomeScreen = false;

  _: any = _;
  user: any = {
    user_bank: "",
    user_bank_description: "",
    user_name: "",
    user_tel: "",
  };

  orginUserInfo: any = {
    user_bank: "",
    user_bank_description: "",
    user_name: "",
    user_tel: "",
  };

  enabledPushNotification: boolean;

  constructor(
    private afs: AngularFirestore,
    //    private fa: FirebaseApp,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    public auth: AuthService,
    public msg: MessagingService,
    public Pwa: PwaService
  ) {}

  invoiceValidators() {
    if (this.user.invoice_id == undefined) return true;
    return /^(\d{8}|)$/.test(this.user.invoice_id);
  }

  ngOnInit() {
    window.sessionStorage.setItem("urlBeforeLogin", "/setting");

    this.userService.userGet().subscribe((resp: any) => {
      if (resp.error_mesg !== "" && resp.status !== "ok") {
        this.toastService.error("查無使用者，請重新登入!");
        this.router.navigate(["/"]);
      }

      if (!resp.item) {
        return;
      }

      this.user = resp.item;
      this.orginUserInfo = Object.assign({}, this.user);
    });

    this.auth.user$
      .pipe(
        filter((user) => !!user), // filter null
        take(1)
      ) // take first real user
      .subscribe((user) => {
        if (user) {
          // console.log(user);
          this.showPushNotificationAction =
            user.device === "web" || user.device === "android";
          this.showAddToHomeScreen = user.device === "android";

          const localFcm = localStorage.getItem("localFcm");
          const currentFcmTokens = user.fcmTokens || {};
          if (!currentFcmTokens[localFcm]) {
            console.log("尚未註冊FCM");
            this.enabledPushNotification = false;
          } else {
            console.log("已經有註冊FCM");
            this.enabledPushNotification = true;
          }
        }
      });
  }

  addToHomeScreen() {
    if (this.Pwa.promptEvent) {
      this.Pwa.promptEvent.prompt();
      this.Pwa.promptEvent.userChoice.then(function (choiceResult) {
        console.log(choiceResult.outcome);

        if (choiceResult.outcome == "dismissed") {
          console.log("使用者取消安裝");
        } else {
          console.log("使用者安裝");
        }
      });

      this.Pwa.promptEvent = null;
    }
  }

  pushNotificationAction() {
    // 使用者願意接收通知
    if (!this.enabledPushNotification) {
      console.log("使用者願意接收通知");

      if (firebase.messaging.isSupported()) {
        this.auth.user$
          .pipe(
            filter((user) => !!user), // filter null
            take(1)
          ) // take first real user
          .subscribe((user) => {
            if (user) {
              if (firebase.messaging.isSupported()) {
                console.log("Great, This device is supported FCM.");
                this.msg.getPermission(user);
                this.msg.monitorRefresh(user);
                this.msg.receiveMessages();

                this.enabledPushNotification
                  ? (this.enabledPushNotification = false)
                  : (this.enabledPushNotification = true);

                console.log(this.enabledPushNotification);
              } else {
                console.log("This device is NOT supported FCM!!!");
              }
            }
          });
      } else {
        console.log("This device is NOT supported FCM!!!");
      }
    } else {
      // 使用者不願意接收通知
      console.log("使用者不願意接收通知");
      const token = localStorage.getItem("localFcm");

      this.auth.user$
        .pipe(
          filter((user) => !!user), // filter null
          take(1)
        ) // take first real user
        .subscribe((user) => {
          if (user) {
            // unsubscribe firebase
            //this.fa.messaging().deleteToken(token);
            firebase.messaging().deleteToken();

            // remove localStorage
            localStorage.removeItem("localFcm");

            // set Firestore user's fcmTokens to false by given name
            const userRef = this.afs.collection("users").doc(user.uid);
            const tokens = { ...user.fcmTokens, [token]: false };
            userRef.update({ fcmTokens: tokens });

            // 恢復 checkbox狀態
            this.enabledPushNotification = false;
          }
        });
    }
  }

  userBankUpdate() {
    this.userService.userBankUpdate(this.user).subscribe((resp: any) => {
      if (resp.error_mesg !== "" && resp.status !== "ok") {
        this.toastService.error("更新失敗，請重整瀏覽器並再次嘗試更新!");
      }
      this.toastService.info("更新完成!");
      this.orginUserInfo = Object.assign({}, this.user);
    });
  }

  isBankInfoChange() {
    return (
      this.user.user_bank !== this.orginUserInfo.user_bank ||
      this.user.user_bank_description !==
        this.orginUserInfo.user_bank_description ||
      this.user.invoice_id !== this.orginUserInfo.invoice_id
    );
  }

  userSettingUpdate() {
    if (_.isEqual(this.user, this.orginUserInfo)) {
      return;
    }

    if (this.isBankInfoChange()) {
      this.userBankUpdate();
    }
  }
}
