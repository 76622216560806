import { AuthGuard } from "./core/auth.guard";
import { CampSearchComponent } from "./camp-search/camp-search.component";
import { CampSearchResultComponent } from "./camp-search/camp-search-result/camp-search-result.component";
import { ExperienceComponent } from "./experience/experience.component";
import { HomeComponent } from "./home/home.component";
import { HomePrivacyComponent } from "./home/privacy/privacy.component";
import { IcampingIntroComponent } from "./icamping-intro/icamping-intro.component";
import { IcampingRuleComponent } from "./icamping-rule/icamping-rule.component";
import { LikeComponent } from "./like/like.component";
import { LoginComponent } from "./login/login.component";
import { MeComponent } from "./me/me.component";
import { MeOrderComponent } from "./me/order/order.component";
import { MesgComponent } from "./mesg/mesg.component";
import { MesgInfoComponent } from "./mesg/info/info.component";
import { MeTripComponent } from "./me/trip/trip.component";
import { MeTripEditComponent } from "./me/trip/edit/edit.component";
import { MeTripInfoCaseDetailComponent } from "./me/trip/info/case-detail/case-detail.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SettingComponent } from "./setting/setting.component";
import { StoreComponent } from "./store/store.component";
import { StoreStuffSearchResultComponent } from "./store/stuff/search-result/search-result.component";
import { StoreSearchComponent } from "./store-search/store-search.component";
import { StoreSearchResultComponent } from "./store-search-result/store-search-result.component";
import { StoreSearchMetadataComponent } from "./store-search-metadata/store-search-metadata.component";
import { UserProfileComponent } from "./user/profile/profile.component";
import { UserVerifyComponent } from "./user/verify/verify.component";
import { StestComponent } from "./stest/stest.component";
import { HotSearchComponent } from "./hot-search/hot-search.component";
import { StoreTelCallComponent } from "./store-tel-call/store-tel-call.component";

//
const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "experience/:name",
    component: ExperienceComponent,
  },
  {
    path: "camp/search",
    component: CampSearchComponent,
    //runGuardsAndResolvers: 'always',
  },
  {
    path: "camp/search-result",
    component: CampSearchResultComponent,
    //runGuardsAndResolvers: 'always',
  },
  {
    path: "store/search",
    component: StoreSearchComponent,
  },
  {
    path: "store/search-result",
    component: StoreSearchResultComponent,
  },
  {
    path: "store/search-result/:name",
    component: StoreSearchMetadataComponent,
  },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "privacy",
    component: HomePrivacyComponent,
  },
  {
    path: "stest",
    component: StestComponent,
  },
  {
    path: "like",
    component: LikeComponent,
    canActivate: [AuthGuard],
  },
  { path: "login", component: LoginComponent },
  {
    path: "me",
    component: MeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "mesg",
    component: MesgComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "mesg/:mesg_id",
    component: MesgInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "store/stuff/search-result",
    component: StoreStuffSearchResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "store/glamping",
    redirectTo: "store/glamping900",
    pathMatch: "full",
  },
  {
    path: "store/:store_name",
    component: StoreComponent,
  },
  {
    path: "store-tel/:store_tel",
    component: StoreTelCallComponent,
  },
  {
    path: "me/trip/info/:store_name/:case_id",
    component: MeTripInfoCaseDetailComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "me/trip/edit/:store_name/:case_id",
    component: MeTripEditComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "me/trip",
    component: MeTripComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "me/order",
    component: MeOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "verify/:phoneNumber/:uid/:status",
    component: UserVerifyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "setting",
    component: SettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "icamping-rule",
    component: IcampingRuleComponent,
  },
  {
    path: "icamping-intro",
    component: IcampingIntroComponent,
  },
  {
    path: "hot-search",
    component: HotSearchComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
