export const environment = {
  baseUrl: "https://m.icamping.app",
  // cloudFunctionGuest: "https://us-central1-icamping-tree.cloudfunctions.net",
  // cloudFunctionEgg:
  //   "https://us-central1-icamping-egg-hosting.cloudfunctions.net",
  cloudFunctionGuest:
    "https://us-central1-icamping-tree-canary.cloudfunctions.net",
  cloudFunctionEgg:
    "https://us-central1-icamping-egg-hosting-canary.cloudfunctions.net",
  //cloudEndpointEgg: 'https://icamping-core.appspot.com/api/egg/v1',
  //cloudEndpointGuest: 'https://icamping-core.appspot.com/api/guest/v1',
  //cloudEndpointMockup: 'https://icamping-core.appspot.com/api/mockup/v1',
  cloudEndpointEgg:
    "https://api-egg-prod-tier-1-wwclgij22a-an.a.run.app/api/egg/v1",
  //cloudEndpointGuest: "https://icamping-core-canary.appspot.com/api/guest/v1",
  cloudEndpointGuestTier1:
    "https://api-guest-prod-tier-1-wwclgij22a-an.a.run.app/api/guest/v1",
  cloudEndpointGuestTier2:
    "https://api-guest-prod-tier-2-wwclgij22a-an.a.run.app/api/guest/v1",
  cloudEndpointApiKey: "AIzaSyAKQXJQUSQUChNI3-RklARZWaIzI5hh3ds",
  googleMapApiKey: "AIzaSyANhT44UpeItWoXZTZD78MJPtOOaIfL5ZY",
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAJXPcgzSBYykateHFsh2d1lXtiIaB1Bbs",
    authDomain: "m.icamping.app",
    // authDomain: "icamping-tree.firebaseapp.com",
    databaseURL: "https://icamping-tree.firebaseio.com",
    projectId: "icamping-tree",
    storageBucket: "icamping-tree.appspot.com",
    messagingSenderId: "492912938841",
  },
};
