import { AuthService } from "./core/auth.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NavbarComponent, ToastService } from "ng-uikit-pro-standard";
import * as moment from "moment-timezone";
import {
  ActivatedRoute,
  Router,
  RoutesRecognized,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { StoreService } from "./service/store.service";
import { HomeService } from "./service/home.service";
import { Observable } from "rxjs/Observable";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("sidenav") sidenav: NavbarComponent;
  @ViewChild("navbar", { static: true }) navbar: NavbarComponent;

  storeKey: any;
  pageLoadingIcon: boolean;
  stores$: Observable<any[]>;
  storeNameSelected = "";
  showMenu$;
  prevURL = "/";
  curURL = "/";

  constructor(
    public auth: AuthService,
    private router: Router,
    private _homeService: HomeService,
    private _storeService: StoreService,
    private _toastService: ToastService
  ) {
    this.router.events.subscribe((event: Event) => {
      console.log(event);

      switch (true) {
        case event instanceof NavigationStart: {
          console.log("NavigationStart");
          let e: any = event;
          console.log(e);

          this._storeService.showPageLoading();
          if (e.id == 1) {
            window.sessionStorage.setItem("urlBeforeLogin", e.url);
          }

          break;
        }
        case event instanceof NavigationEnd: {
          const navigationEvent = event as NavigationEnd;
          let e: any = event;
          console.log(e);
          this.prevURL = this.curURL;
          this.curURL = e.url;
          if (this.curURL === "/login" && e.id >= 3) {
            console.log(this.prevURL);
            window.sessionStorage.setItem("urlBeforeLogin", this.prevURL);
          }

          console.log(this.prevURL, this.curURL, e.id);
          //完成login後，導回原頁面
          if (
            this.prevURL == "/login" &&
            this.curURL == "/login" &&
            e.id == 2
          ) {
            console.log("完成login後，導回原頁面");
            this.router.navigate(["/"]);
          }
        }
        default: {
          break;
        }
      }
    });

    this.showMenu$ = this._homeService.showMenu$.subscribe(() => {
      this.sidenav.show();
    });
  }

  goSearch: boolean = false;

  ngOnInit() {
    this._storeService.getPageLoading().subscribe((pageLoading: boolean) => {
      this.pageLoadingIcon = pageLoading;
    });
    this.stores$ = this._homeService.getStores();
    moment.tz.setDefault("Asia/Taipei");
    setTimeout(() => {
      this.navbar.height = 290;
    }, 1500);
  }

  toogleSearch() {
    this.goSearch = !this.goSearch;
    // console.log(this.stores$);
    // this.stores$.subscribe((stores: any) => {
    //   console.log(stores);
    // });
  }

  // //Scroll content to top after switched to new page.
  onScrollContentToTop(event: any) {
    window.scroll(0, 0);
  }

  logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.auth.signOut();
  }

  goStoreSearchResult(city: string) {
    this._homeService.setDefaultExperienceFilter();
    this._homeService.setAllStoreFilter(city);
    this._homeService.setStoreSrearchResultSlogan(`以下為 ${city} 的相關營地`);
    this.router.navigate(["/store/search-result"]);
  }

  onstoreNameSelectedChange(e) {
    this.routeStore();
  }

  isCampSearchFormSubmitted(submitted: boolean) {
    if (!submitted) {
      console.log("camp search form is submitted.");
    } else {
      console.log("camp search form failed to be submitted.");
    }
  }

  routeStore() {
    console.log(this.storeNameSelected);
    const store_name = this._homeService.mapStoreAlias(this.storeNameSelected);
    console.log(store_name);
    if (store_name && store_name.length > 3) {
      this.toogleSearch();
      this.router.navigate([`/store/${store_name}`]);
    } else {
      this._toastService.warning("找不到符合的店家。");
    }
  }

  ngOndestroy() {
    this.showMenu$.unsubscribe();
  }
}
