import { AuthService } from "../core/auth.service";
import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { StoreService } from "../service/store.service";
import { ToastService } from "ng-uikit-pro-standard";
import { getAuth, RecaptchaVerifier } from "@angular/fire/auth";

interface IcampingUserModel {
  email: string;
  password: string;
}
declare global {
  interface Window {
    confirmationResult: any;
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  icampingUserModel = {} as IcampingUserModel;
  icampingUserModelResetPassword = {} as IcampingUserModel;
  newIcampingUserModel = {} as IcampingUserModel;
  showSpinner: boolean;
  showLoginWindow: boolean;
  showPhoneLoginWindow: boolean;
  phoneModel: string;

  recaptchaVerifier!: RecaptchaVerifier;

  constructor(
    private _storeService: StoreService,
    private toastService: ToastService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this._storeService.hidePageLoading();
    const keepLoading = window.sessionStorage.getItem("keepLoading");

    if (keepLoading !== "true") {
      console.log("showSpinner is false");
      this.showSpinner = false;
    } else {
      console.log("showSpinner is true");

      this.showSpinner = true;
      setTimeout(() => {
        console.log("showSpinner for 5 sec.");
        this.showSpinner = false;
        window.sessionStorage.removeItem("keepLoading");
      }, 5000);
    }
    this.showLoginWindow = true;
    this.showPhoneLoginWindow = true;
    // Initialize reCAPTCHA verifier
    this.recaptchaVerifier = new RecaptchaVerifier(
      "signUpPhone-btn", // The container ID
      {
        size: "invisible",
        "data-sitekey": "6LfgyS4qAAAAAEMkdgOMCjx6LyliykafNJvq5Xow",
        callback: (response: any) => {
          console.log("reCAPTCHA solved, asking for verification code now.");
          // alert("reCAPTCHA solved, asking for verification code now.");
        },
        "expired-callback": () => {
          console.error("Recaptcha expired, please solve it again.");
          // alert("Recaptcha expired, please solve it again.");
        },
        "data-error-callback": (response: any) => {
          console.error("Recaptcha error happened.");
          // alert("Recaptcha error happened.");
          // alert(response);
        },
      },
      this.auth.auth
    );

    this.recaptchaVerifier.render().then((widgetId) => {
      console.log("reCAPTCHA widget ID:", widgetId);
      // alert(widgetId);
    });

    this.recaptchaVerifier.verify().then((token) => {
      console.log(token);
      // alert(token);
    });
  }

  signInWithPhone() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true");

    // Ensure the phoneModel starts with '+886'
    if (this.phoneModel.startsWith("0")) {
      this.phoneModel = this.phoneModel.substring(1); // Remove the leading '0'
    }
    this.phoneModel = `+886${this.phoneModel}`; // Add the '+886' prefix
    console.log(this.phoneModel);

    //const phoneNumber = "+886937184606"; // Replace with dynamic phone number input
    const phoneNumber = this.phoneModel; // Replace with dynamic phone number input
    const appVerifier = this.recaptchaVerifier;
    //const auth_phone = getAuth();
    //this.auth.phoneLogin(auth_phone, phoneNumber, appVerifier);
    this.auth.phoneLogin(this.auth.auth, phoneNumber, appVerifier);
  }

  signInWithGoogle() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286
    this.auth.googleLogin();
  }

  signInWithFacebook() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286
    this.auth.facebookLogin();
  }

  signInWithIcamping(icampingUserModel) {
    console.log("Step1: 嘗試用該帳號密碼驗證舊愛露營使用者資料庫");
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286

    this.showLoginWindow = false;
    this.auth
      .emailLogin(icampingUserModel.email, icampingUserModel.password)
      .then((result: any) => {
        console.log(result);

        if (result.code === "auth/user-not-found") {
          console.log(
            "Step1: 該使用者沒有在新版愛露營建立過帳號，且與舊版愛露營帳號密碼驗證失敗！"
          );
          console.log("Step2: 為該使用者新增新版愛露營帳號！");
          // 新增該使用者到新版icamping, 建立好後會跑casebinding
          this.signUpWithIcamping(
            icampingUserModel.email,
            icampingUserModel.password
          );
        } else if (result.code === "auth/wrong-password") {
          console.error(
            "Step2.2: 該使用者在新版愛露營有帳號，但是Firebase密碼輸入錯誤！"
          );
          this.toastService.error("新版愛露營密碼錯誤");
          this.icampingUserModel.password = "";
          this.showSpinner = false;
          this.showLoginWindow = true;
          return false;
        } else {
          console.error(
            "Step2.2: 該使用者在新版愛露營有帳號，且Firebase密碼驗證，我們要把user導回到home！"
          );
          this.auth.updateUserData(result.user).then(() => {
            this.auth.icampingLocalStorageSet(
              result.user.email,
              result.user.uid
            );
            this.home();
          });
        }
      });
  }

  signUpWithIcamping(email: string, password: string) {
    if (email.includes("@gmail.com")) {
      return;
    }

    this.showSpinner = true;

    this.auth
      .emailSignUp(email, password)
      .then((authorized) => {
        if (authorized.user) {
          console.log("Step6: 註冊新版愛露營帳號成功!");
          console.log("Step7: 更新新版使用者資料到icamping-tree Firestore！");
          this.auth.updateUserData(authorized.user).then(() => {
            // console.log('Step8: 導到 /profile做手機驗證');
            console.log("Step8: 做case binding並導入home");
            this.auth.icampingLocalStorageSet(
              authorized.user.email,
              authorized.user.uid
            );
            this.afterSignIn();
          });
        } else {
          this.showSpinner = false;
          this.showLoginWindow = true;
        }
      })
      .catch((error) => {
        this.toastService.error(`愛入營帳號註冊失敗，請嘗試，謝謝`);
        console.log(error);
        this.showSpinner = false;
        this.showLoginWindow = true;
      });
  }

  resetPassword(icampingUserModelResetPassword) {
    this.auth
      .resetPassword(icampingUserModelResetPassword.email)
      .then(() => {});
  }

  signOut() {
    this.auth.signOut();
  }

  private afterSignIn() {
    this.home();
    this.showSpinner = false;
  }

  private home() {
    window.location.href = `${environment.baseUrl}`;
  }
}
