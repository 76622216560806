<div class="row text-center pb-3 mt2r">
  <!-- Material form  -->
  <div class="col"></div>
  <div class="col-sm-10 col-12">
    <mdb-card-body class="px-lg-5">
      <div class="d-flex justify-content-center">
        <form class="text-center grey-text w-100">
          <img
            class="portrait z-depth-1 rounded-circle"
            src="{{ user.photoURL }}"
            onError="this.src='../../assets/images/user-placeholder.jpg';"
            alt=""
          />
          <!-- Name -->
          <div class="md-form mt-3">
            <input
              type="text"
              id="materialFormName"
              name="uname"
              class="form-control"
              [(ngModel)]="user.user_name"
              mdbInput
              disabled
            />
            <label for="materialFormName">名稱(不可更改)</label>
          </div>
          <!-- Tel -->
          <div class="md-form">
            <label for="materialFormPhone" style="font-size: 13px"
              >電話(不可更改)</label
            >
            <p class="text-left ic-setting-text">{{ user.user_tel }}</p>
          </div>

          <!-- User Bank Desc -->
          <div class="md-form">
            <input
              type="text"
              id="materialFormBankDesc"
              name="bankDesc"
              class="form-control"
              [(ngModel)]="user.user_bank_description"
              [validate]="false"
              mdbInput
              style="font-size: 12px"
              placeholder="範例：銀行名稱-分行名稱-戶名"
            />
            <label for="materialFormBankDesc">銀行資訊</label>
          </div>

          <!-- User Bank -->
          <div class="md-form">
            <input
              type="text"
              id="materialFormBank"
              name="bank"
              class="form-control"
              [(ngModel)]="user.user_bank"
              [mdbValidation]="false"
              mdbInput
              style="font-size: 12px"
              placeholder="範例：銀行代號-銀行帳號（如：012-88888888888"
            />
            <label for="materialFormBank">銀行帳號</label>
          </div>

          <div class="md-form">
            <input
              type="text"
              id="materialFormInvoiceId"
              name="invoice_id"
              class="form-control"
              [(ngModel)]="user.invoice_id"
              [mdbValidation]="false"
              mdbInput
              style="font-size: 12px"
              placeholder="統一編號(範例):54894757，不需要請空白"
            />
            <label for="materialFormInvoiceId">統一編號</label>
            <mdb-error *ngIf="!invoiceValidators()"
              >請留白或是請留白或是輸入8個數字</mdb-error
            >
          </div>

          <div
            class="row mt-3 justify-content-center"
            *ngIf="showPushNotificationAction"
          >
            <form>
              <mdb-checkbox
                (click)="pushNotificationAction()"
                [checked]="enabledPushNotification"
                style="font-size: 13px; padding: 1px 30px !important"
                >願意接收愛露營通知?</mdb-checkbox
              >
              <mdb-breadcrumb style="font-size: 13px"
                >我們將發送[訂單即時狀態更新]與[營區最新狀況]</mdb-breadcrumb
              >
            </form>
          </div>

          <div
            class="row mt-3 justify-content-center"
            *ngIf="showAddToHomeScreen"
          >
            <form>
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="addToHomeScreen()"
                style="font-size: 13px"
              >
                把愛露營App加入到手機桌面
              </button>
              <div class="mt-1">
                <mdb-breadcrumb style="font-size: 13px"
                  >加入到桌面後，您可快速開啟愛露營App</mdb-breadcrumb
                >
              </div>
            </form>
          </div>
          <!-- Sign in button -->
          <!-- FIXME: 20190126 axa 暫時把 isEqual拿掉為了跑cypress-->
          <!-- <button *ngIf="!_.isEqual(user, orginUserInfo)" id="user-setting-update" mdbBtn color="info" outline="true" rounded="true" block="true" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                  (click)="userSettingUpdate()"  type="submit">更新資訊</button> -->
          <button
            id="user-setting-update"
            mdbBtn
            block="true"
            class="z-depth-0 my-4 waves-effect btn-ic"
            mdbWavesEffect
            (click)="userSettingUpdate()"
            [disabled]="!invoiceValidators()"
            type="submit"
          >
            [儲存]銀行資訊與帳號
          </button>
        </form>
      </div>
    </mdb-card-body>
  </div>
  <div class="col"></div>
</div>
